export const DEBUG = window.location.hostname == "localhost";

export const isProd = () => window.location.host === "confidence.corsis.com";

export const isReviewPage = () => window.location.href.match(".*/admin/reports/[0-9]+/tracks/[0-9]+");

export const isReportOverviewPage = () => window.location.href.match(".*/admin/reports/[0-9]+/edit");

export const getParameterByName = (name, url = "") => {
  if (!url) url = window.location.href;
  name = name.replace(/[[\]]/g, "\\$&");
  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
  const results = regex.exec(url);
  if (results == null) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};

export const getCurrentVersion = () => {
  return new URLSearchParams(window.location.search).get("v") ?? "";
};
